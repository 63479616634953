import axios from 'axios';
import React, { Component } from 'react';
import Layout from '../components/layout';

class Contact extends Component {
    state = {
        elqSiteId: '452605141',
        elqCampaignId: '',
        elqFormName: 'Demand_2020_TAP_OIT_Microsite_EUR_CH:LP_Form',
        firstName: '',
        lastName: '',
        emailAddress: '',
        company: '',
        title: '',
        businessPhone: '',
        address1: '',
        zipPostal: '',
        country: '',
        defaultCheck1: '',
        defaultCheck2: '',
        defaultCheck3: '',
        defaultCheck4: '',
        defaultCheck5: '',
        defaultCheck6: '',
        defaultCheck7: '',
        defaultCheck8: '',
        commentsQuestions: '',
        emailOptin: '',
        sFDCLastCampaignID: '',
        leadSourceMostRecent: '',
        sourceURL: '',
    };

    handleInputChange = (event) => {
        const { target } = event;
        // const { value } = target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        this.setState({
            [name]: value,
        });
    };

    render() {
        return (
            <Layout>
                <div className="row">
                    <div className="col-12 col-md-8 blockformtitle">
                        <h1>Contact Us</h1>
                        <p>
                            If you have any questions regarding our solutions and services or any
                            other comments, please contact us.
                        </p>
                        <br />
                        <p>
                            For ONESOURCE support queries please refer to the ONESOURCE &nbsp;
                            <a
                                href="https://tax.thomsonreuters.co.uk/support/onesource"
                                target="_blank"
                            >
                                support page.
                            </a>
                            <br />
                            For Checkpoint support please refer to the Checkpoint &nbsp;
                            <a
                                href="https://tax.thomsonreuters.co.uk/checkpoint/international-tax-research/contact-us"
                                target="_blank"
                            >
                                support page.
                            </a>
                        </p>
                        <br />
                    </div>
                    <div className="col-12 blockform">
                        <form method="post" action="https://s452605141.t.eloqua.com/e/f2" name="Demand_2020_TAP_OIT_Microsite_EUR_CH:LP_Form">
                            <input
                                type="hidden"
                                name="elqSiteId"
                                value={this.state.elqSiteId}
                                required
                            />
                            <input type="hidden" name="elqCampaignId" />
                            <input
                                type="hidden"
                                name="elqFormName"
                                value={this.state.elqFormName}
                                required
                            />
                            <div className="form-group">
                                <label htmlFor="firstName">
                                    First name <span className="requiredStar">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="firstName"
                                    id="fe14621"
                                    value={this.state.firstName}
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastName">
                                    Last name <span className="requiredStar">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="lastName"
                                    id="fe14622"
                                    value={this.state.lastName}
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="emailAddress">
                                    Email Address <span className="requiredStar">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="email"
                                    name="emailAddress"
                                    id="fe14623"
                                    value={this.state.emailAddress}
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="company">
                                    Company <span className="requiredStar">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="company"
                                    id="fe14624"
                                    value={this.state.company}
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="title">
                                    Job Title <span className="requiredStar">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="title"
                                    id="fe14625"
                                    value={this.state.title}
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="businessPhone">
                                    Business Phone <span className="requiredStar">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="phone"
                                    name="businessPhone"
                                    id="fe14626"
                                    value={this.state.businessPhone}
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="address1">
                                    Organisation address <span className="requiredStar">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="address1"
                                    id="fe14643"
                                    value={this.state.address1}
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="zipPostal">
                                    Zip or Postal Code <span className="requiredStar">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="zipPostal"
                                    id="fe14644"
                                    value={this.state.zipPostal}
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="country">
                                    Country <span className="requiredStar">*</span>
                                </label>
                                <select
                                    className="form-control"
                                    id="fe14627"
                                    name="country"
                                    value={this.state.country}
                                    onChange={this.handleInputChange}
                                    required
                                >
                                    <option selected="" value="">
                                        -- Please Select --
                                    </option>
                                    <option value="US">United States</option>
                                    <option value="GB">United Kingdom</option>
                                    <option value="CA">Canada</option>
                                    <option value="IN">India</option>
                                    <option value="NL">Netherlands</option>
                                    <option value="AU">Australia</option>
                                    <option value="ZA">South Africa</option>
                                    <option value="FR">France</option>
                                    <option value="DE">Germany</option>
                                    <option value="SG">Singapore</option>
                                    <option value="SE">Sweden</option>
                                    <option value="BR">Brazil</option>
                                    <option selected="" value="">
                                        ----------------- &nbsp;
                                    </option>
                                    <option value="AF">Afghanistan</option>
                                    <option value="AX">Aland Islands</option>
                                    <option value="AL">Albania</option>
                                    <option value="DZ">Algeria</option>
                                    <option value="AS">American Samoa</option>
                                    <option value="AD">Andorra</option>
                                    <option value="AO">Angola</option>
                                    <option value="AI">Anguilla</option>
                                    <option value="AQ">Antarctica</option>
                                    <option value="AG">Antigua and Barbuda</option>
                                    <option value="AR">Argentina</option>
                                    <option value="AM">Armenia</option>
                                    <option value="AW">Aruba</option>
                                    <option value="AU">Australia</option>
                                    <option value="AT">Austria</option>
                                    <option value="AZ">Azerbaijan</option>
                                    <option value="BS">Bahamas</option>
                                    <option value="BH">Bahrain</option>
                                    <option value="BD">Bangladesh</option>
                                    <option value="BB">Barbados</option>
                                    <option value="BY">Belarus</option>
                                    <option value="BE">Belgium</option>
                                    <option value="BZ">Belize</option>
                                    <option value="BJ">Benin</option>
                                    <option value="BM">Bermuda</option>
                                    <option value="BT">Bhutan</option>
                                    <option value="BO">Bolivia</option>
                                    <option value="BA">Bosnia and Herzegovina</option>
                                    <option value="BW">Botswana</option>
                                    <option value="BV">Bouvet Island</option>
                                    <option value="BR">Brazil</option>
                                    <option value="IO">Brit/Indian Ocean Terr.</option>
                                    <option value="BN">Brunei Darussalam</option>
                                    <option value="BG">Bulgaria</option>
                                    <option value="BF">Burkina Faso</option>
                                    <option value="BI">Burundi</option>
                                    <option value="KH">Cambodia</option>
                                    <option value="CM">Cameroon</option>
                                    <option value="CA">Canada</option>
                                    <option value="CV">Cape Verde</option>
                                    <option value="KY">Cayman Islands</option>
                                    <option value="CF">Central African Republic</option>
                                    <option value="TD">Chad</option>
                                    <option value="CL">Chile</option>
                                    <option value="CN">China</option>
                                    <option value="CX">Christmas Island</option>
                                    <option value="CC">Cocos (Keeling) Islands</option>
                                    <option value="CO">Colombia</option>
                                    <option value="KM">Comoros</option>
                                    <option value="CG">Congo</option>
                                    <option value="CD">Congo, The Dem. Republic Of</option>
                                    <option value="CK">Cook Islands</option>
                                    <option value="CR">Costa Rica</option>
                                    <option value="CI">Ivory Coast</option>
                                    <option value="HR">Croatia</option>
                                    <option value="CU">Cuba</option>
                                    <option value="CY">Cyprus</option>
                                    <option value="CZ">Czech Republic</option>
                                    <option value="DK">Denmark</option>
                                    <option value="DJ">Djibouti</option>
                                    <option value="DM">Dominica</option>
                                    <option value="DO">Dominican Republic</option>
                                    <option value="EC">Ecuador</option>
                                    <option value="EG">Egypt</option>
                                    <option value="SV">El Salvador</option>
                                    <option value="GQ">Equatorial Guinea</option>
                                    <option value="ER">Eritrea</option>
                                    <option value="EE">Estonia</option>
                                    <option value="ET">Ethiopia</option>
                                    <option value="FK">Falkland Islands</option>
                                    <option value="FO">Faroe Islands</option>
                                    <option value="FJ">Fiji</option>
                                    <option value="FI">Finland</option>
                                    <option value="FR">France</option>
                                    <option value="GF">French Guiana</option>
                                    <option value="PF">French Polynesia</option>
                                    <option value="TF">French Southern Terr.</option>
                                    <option value="GA">Gabon</option>
                                    <option value="GM">Gambia</option>
                                    <option value="GE">Georgia</option>
                                    <option value="DE">Germany</option>
                                    <option value="GH">Ghana</option>
                                    <option value="GI">Gibraltar</option>
                                    <option value="GB">United Kingdom</option>
                                    <option value="GR">Greece</option>
                                    <option value="GL">Greenland</option>
                                    <option value="GD">Grenada</option>
                                    <option value="GP">Guadeloupe</option>
                                    <option value="GU">Guam</option>
                                    <option value="GT">Guatemala</option>
                                    <option value="GN">Guinea</option>
                                    <option value="GW">Guinea-Bissau</option>
                                    <option value="GY">Guyana</option>
                                    <option value="HT">Haiti</option>
                                    <option value="HM">Heard/McDonald Isls.</option>
                                    <option value="HN">Honduras</option>
                                    <option value="HK">Hong Kong</option>
                                    <option value="HU">Hungary</option>
                                    <option value="IS">Iceland</option>
                                    <option value="IN">India</option>
                                    <option value="ID">Indonesia</option>
                                    <option value="IR">Iran</option>
                                    <option value="IQ">Iraq</option>
                                    <option value="IE">Ireland</option>
                                    <option value="IL">Israel</option>
                                    <option value="IT">Italy</option>
                                    <option value="JM">Jamaica</option>
                                    <option value="JP">Japan</option>
                                    <option value="JO">Jordan</option>
                                    <option value="KZ">Kazakhstan</option>
                                    <option value="KE">Kenya</option>
                                    <option value="KI">Kiribati</option>
                                    <option value="KP">Korea (North)</option>
                                    <option value="KR">Korea (South)</option>
                                    <option value="XK">Kosovo</option>
                                    <option value="KW">Kuwait</option>
                                    <option value="KG">Kyrgyzstan</option>
                                    <option value="LA">Laos</option>
                                    <option value="LV">Latvia</option>
                                    <option value="LB">Lebanon</option>
                                    <option value="LS">Lesotho</option>
                                    <option value="LR">Liberia</option>
                                    <option value="LY">Libya</option>
                                    <option value="LI">Liechtenstein</option>
                                    <option value="LT">Lithuania</option>
                                    <option value="LU">Luxembourg</option>
                                    <option value="MO">Macau</option>
                                    <option value="MK">Macedonia</option>
                                    <option value="MG">Madagascar</option>
                                    <option value="MW">Malawi</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="MV">Maldives</option>
                                    <option value="ML">Mali</option>
                                    <option value="MT">Malta</option>
                                    <option value="MH">Marshall Islands</option>
                                    <option value="MQ">Martinique</option>
                                    <option value="MR">Mauritania</option>
                                    <option value="MU">Mauritius</option>
                                    <option value="YT">Mayotte</option>
                                    <option value="MX">Mexico</option>
                                    <option value="FM">Micronesia</option>
                                    <option value="MD">Moldova</option>
                                    <option value="MC">Monaco</option>
                                    <option value="MN">Mongolia</option>
                                    <option value="MS">Montserrat</option>
                                    <option value="MA">Morocco</option>
                                    <option value="MZ">Mozambique</option>
                                    <option value="MM">Myanmar</option>
                                    <option value="MP">N. Mariana Isls.</option>
                                    <option value="NA">Namibia</option>
                                    <option value="NR">Nauru</option>
                                    <option value="NP">Nepal</option>
                                    <option value="NL">Netherlands</option>
                                    <option value="AN">Netherlands Antilles</option>
                                    <option value="NC">New Caledonia</option>
                                    <option value="NZ">New Zealand</option>
                                    <option value="NI">Nicaragua</option>
                                    <option value="NE">Niger</option>
                                    <option value="NG">Nigeria</option>
                                    <option value="NU">Niue</option>
                                    <option value="NF">Norfolk Island</option>
                                    <option value="NO">Norway</option>
                                    <option value="OM">Oman</option>
                                    <option value="PK">Pakistan</option>
                                    <option value="PW">Palau</option>
                                    <option value="PS">Palestinian Territory, Occupied</option>
                                    <option value="PA">Panama</option>
                                    <option value="PG">Papua New Guinea</option>
                                    <option value="PY">Paraguay</option>
                                    <option value="PE">Peru</option>
                                    <option value="PH">Philippines</option>
                                    <option value="PN">Pitcairn</option>
                                    <option value="PL">Poland</option>
                                    <option value="PT">Portugal</option>
                                    <option value="PR">Puerto Rico</option>
                                    <option value="QA">Qatar</option>
                                    <option value="RE">Reunion</option>
                                    <option value="RO">Romania</option>
                                    <option value="RU">Russian Federation</option>
                                    <option value="RW">Rwanda</option>
                                    <option value="KN">Saint Kitts and Nevis</option>
                                    <option value="LC">Saint Lucia</option>
                                    <option value="WS">Samoa</option>
                                    <option value="SM">San Marino</option>
                                    <option value="ST">Sao Tome/Principe</option>
                                    <option value="SA">Saudi Arabia</option>
                                    <option value="SN">Senegal</option>
                                    <option value="CS">Serbia and Montenegro</option>
                                    <option value="SC">Seychelles</option>
                                    <option value="SL">Sierra Leone</option>
                                    <option value="SG">Singapore</option>
                                    <option value="SK">Slovak Republic</option>
                                    <option value="SI">Slovenia</option>
                                    <option value="SB">Solomon Islands</option>
                                    <option value="SO">Somalia</option>
                                    <option value="ZA">South Africa</option>
                                    <option value="ES">Spain</option>
                                    <option value="LK">Sri Lanka</option>
                                    <option value="SH">St. Helena</option>
                                    <option value="PM">St. Pierre and Miquelon</option>
                                    <option value="VC">St. Vincent and Grenadines</option>
                                    <option value="SD">Sudan</option>
                                    <option value="SR">Suriname</option>
                                    <option value="SJ">Svalbard/Jan Mayen Isls.</option>
                                    <option value="SZ">Swaziland</option>
                                    <option value="SE">Sweden</option>
                                    <option value="CH">Switzerland</option>
                                    <option value="SY">Syria</option>
                                    <option value="TW">Taiwan</option>
                                    <option value="TJ">Tajikistan</option>
                                    <option value="TZ">Tanzania</option>
                                    <option value="TH">Thailand</option>
                                    <option value="TL">Timor-Leste</option>
                                    <option value="TG">Togo</option>
                                    <option value="TK">Tokelau</option>
                                    <option value="TO">Tonga</option>
                                    <option value="TT">Trinidad and Tobago</option>
                                    <option value="TN">Tunisia</option>
                                    <option value="TR">Turkey</option>
                                    <option value="TM">Turkmenistan</option>
                                    <option value="TC">Turks/Caicos Isls.</option>
                                    <option value="TV">Tuvalu</option>
                                    <option value="UG">Uganda</option>
                                    <option value="UA">Ukraine</option>
                                    <option value="AE">United Arab Emirates</option>
                                    <option value="US">United States</option>
                                    <option value="UM">US Minor Outlying Is.</option>
                                    <option value="UY">Uruguay</option>
                                    <option value="UZ">Uzbekistan</option>
                                    <option value="VU">Vanuatu</option>
                                    <option value="VA">Vatican City</option>
                                    <option value="VE">Venezuela</option>
                                    <option value="VN">Viet Nam</option>
                                    <option value="VG">Virgin Islands (British)</option>
                                    <option value="VI">Virgin Islands (US)</option>
                                    <option value="WF">Wallis/Futuna Isls.</option>
                                    <option value="EH">Western Sahara</option>
                                    <option value="YE">Yemen</option>
                                    <option value="ZM">Zambia</option>
                                    <option value="ZW">Zimbabwe</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <p>I am interested in more information</p>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        name="defaultCheck1"
                                        type="checkbox"
                                        checked={this.state.defaultCheck1}
                                        onChange={this.handleInputChange}
                                        id="checkbox-ONESOURCE Corporate Tax_6n91h0g"
                                        value="ONESOURCE Corporate Tax"
                                    />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        ONESOURCE Corporate Tax
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        name="defaultCheck2"
                                        type="checkbox"
                                        checked={this.state.defaultCheck2}
                                        onChange={this.handleInputChange}
                                        id="checkbox-ONESOURCE Indirect Tax_h8hs7kvwh"
                                        value="ONESOURCE Indirect Tax"
                                    />
                                    <label className="form-check-label" htmlFor="defaultCheck2">
                                        ONESOURCE Indirect Tax
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        name="defaultCheck3"
                                        type="checkbox"
                                        checked={this.state.defaultCheck3}
                                        onChange={this.handleInputChange}
                                        id="checkbox-ONESOURCE Statutory Reporting_a7csb5t"
                                        value="ONESOURCE Statutory Reporting"
                                    />
                                    <label className="form-check-label" htmlFor="defaultCheck3">
                                        ONESOURCE Statutory Reporting
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        name="defaultCheck4"
                                        type="checkbox"
                                        checked={this.state.defaultCheck4}
                                        onChange={this.handleInputChange}
                                        id="checkbox-ONESOURCE Workflow Manager_kyfkds5l"
                                        value="ONESOURCE Workflow Manager"
                                    />
                                    <label className="form-check-label" htmlFor="defaultCheck4">
                                        ONESOURCE Workflow Manager
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        name="defaultCheck5"
                                        type="checkbox"
                                        checked={this.state.defaultCheck5}
                                        onChange={this.handleInputChange}
                                        id="checkbox-ONESOURCE Transfer Pricing_4m8p8ijm"
                                        value="ONESOURCE Transfer Pricing"
                                    />
                                    <label className="form-check-label" htmlFor="defaultCheck5">
                                        ONESOURCE Transfer Pricing
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        name="defaultCheck6"
                                        type="checkbox"
                                        checked={this.state.defaultCheck6}
                                        onChange={this.handleInputChange}
                                        id="checkbox-ONESOURCE DataFlow_obdvyupd"
                                        value="ONESOURCE DataFlow"
                                    />
                                    <label className="form-check-label" htmlFor="defaultCheck6">
                                        ONESOURCE DataFlow
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        name="defaultCheck7"
                                        type="checkbox"
                                        checked={this.state.defaultCheck7}
                                        onChange={this.handleInputChange}
                                        id="checkbox-ONESOURCE Tax Provision_dwjngot"
                                        value="ONESOURCE Tax Provision"
                                    />
                                    <label className="form-check-label" htmlFor="defaultCheck7">
                                        ONESOURCE Tax Provision
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        name="defaultCheck8"
                                        type="checkbox"
                                        checked={this.state.defaultCheck8}
                                        onChange={this.handleInputChange}
                                        id="checkbox-ONESOURCE Global Tax Audit Manager_t7gh7cws"
                                        value="ONESOURCE Global Tax Audit Manager"
                                    />
                                    <label className="form-check-label" htmlFor="defaultCheck8">
                                        ONESOURCE Global Tax Audit Manager
                                    </label>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="commentsQuestions"> Comments/Questions</label>
                                <textarea
                                    className="form-control"
                                    name="commentsQuestions"
                                    id="fe14629"
                                    value={this.state.commentsQuestions}
                                    onChange={this.handleInputChange}
                                />
                            </div>

                            <div className="form-group form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="fe14636"
                                    name="emailOptin"
                                    value={this.state.emailOptin}
                                    onChange={this.handleInputChange}
                                />
                                <label className="form-check-label" htmlFor="exampleCheck1">
                                    I would like to receive communications about Thomson Reuters Tax
                                    and Accounting resources, events, products, or services.
                                </label>
                                <small className="form-text text-muted">
                                    (Please note you can manage and update your communication
                                    preferences at any time.)
                                </small>
                            </div>

                            <p>
                                By submitting this form you are acknowledging that you have read and
                                agree to our: &nbsp;
                                <a
                                    href="https://www.thomsonreuters.com/en/privacy-statement.html"
                                    target="_blank"
                                >
                                    Privacy Policy
                                </a>
                            </p>

                            {/* Hide last three input
                                <div className="form-group">
                                    <label htmlFor="sFDCLastCampaignID"> Last SFDC Campaign ID</label>
                                    <input
                                        className="form-control"
                                        name="sFDCLastCampaignID"
                                        id="fe14632"
                                        value={this.state.sFDCLastCampaignID}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="leadSourceMostRecent">
                                        Lead Source - Most Recent
                                    </label>
                                    <input
                                        className="form-control"
                                        name="leadSourceMostRecent"
                                        id="fe14633"
                                        value={this.state.leadSourceMostRecent}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="sourceURL">Source URL</label>
                                    <input
                                        className="form-control"
                                        name="sourceURL"
                                        id="fe14634"
                                        value={this.state.sourceURL}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            */}
                            <button type="submit" className="btn btn-primary btnSubmitForm">
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </Layout>
        );
    }
}
export default Contact;
